import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import InduBolsas from "./InduBolsas";

// ReactDOM.render(<InduBolsas />, document.getElementById("root"));

const container = document.getElementById("root");

const root = ReactDOM.createRoot(container);
root.render(<InduBolsas />);
