import React from "react";
import { DashboardRoutes } from "./DashBoardRoutes";
import { HashRouter, Routes, Route } from "react-router-dom";
export const MainRouter = () => {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route path="*" element={<DashboardRoutes />} />
        </Routes>
      </HashRouter>
    </>
  );
};
