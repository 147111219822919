import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import "../index.css";
import { useFetchGifs } from "../hooks/useFetchGifs";
import { AddCartContext } from "./AddCartContext";

import "../index.css";
export const TarjetaPantalla = () => {
  const { addCart, setAddCart } = useContext(AddCartContext);
  //nuevo
  const [age, setAge] = useState([]);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const { productoId } = useParams();

  const { data: images } = useFetchGifs();

  const getID = (id) => {
    const info = images.find((data) => data.id === id);
    console.log(info);
    return info;
  };
  const ame = getID(productoId);
  const addToCart = () => {
    setAddCart([
      ...addCart,
      {
        id: ame.id,
        name: ame.name,
        url: ame.url,
        size: age.length === 0 ? ame.size[0] : age,
      },
    ]);
    setState({ open: true, vertical: "top", horizontal: "center" });
  };
  const ref = () => {
    let d = [];
    if (ame !== undefined) {
      const { size } = ame;

      d = size.map((data) => data);
    }
    return d;
  };

  const formatoMoneda = (valor) => {
    return parseInt(valor).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
  };
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  return (
    <div
      className="animate__animated animate__fadeIn"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",

        flexWrap: "wrap",
        alignItems: "center",
      }}
    >
      <Card sx={{ width: "300px", justifyContent: "space-between" }}>
        <CardMedia
          component="img"
          height="350"
          image={!ame ? "Cargando" : ame.url}
          alt="Producto"
        />
        <CardContent>
          <Box sx={{ minWidth: 100 }}>
            <FormControl fullWidth variant="filled">
              <InputLabel id="demo-simple-select-label">Referencias</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={""}
                label="Age"
                onChange={handleChange}
              >
                {ref().map((data, index) => (
                  <MenuItem
                    key={index}
                    value={data}
                    // value={[data[0], data[1]]}
                  >
                    Medida: {data.referencia}, Precio:{" "}
                    {formatoMoneda(data.Precio)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Typography gutterBottom variant="h6" component="div">
            {!ame ? "Cargando" : ame.name}{" "}
            {!ame
              ? "Cargando"
              : age.length === 0
              ? ref()[0].referencia
              : age.referencia}
          </Typography>
          <Typography variant="h5" color="blue">
            {!ame
              ? "Cargando"
              : age.length === 0
              ? formatoMoneda(ref()[0].Precio)
              : formatoMoneda(age.Precio)}
          </Typography>

          {/* <Typography gutterBottom variant="caption" component="div">
            {!ame ? "Cargando" : ame.name}
          </Typography>
          <Typography gutterBottom variant="h6" component="div">
            {!ame ? "Cargando" : ame.name}
          </Typography>
          <Typography variant="h5" color="blue">
            {!ame ? "Cargando" : formatoMoneda(ame.size[0].Precio)}
          </Typography> */}
        </CardContent>
        <CardActions>
          <Button
            size="small"
            variant="contained"
            style={{ backgroundColor: "#D98430" }}
            onClick={addToCart}
          >
            Agregar
          </Button>
        </CardActions>
      </Card>

      {ref().map((data, index) => (
        <h1
          key={index}
          style={{
            border: "2px solid black",
            width: "fit-content",
          }}
        >
          Medida: {data.referencia}, Precio: {formatoMoneda(data.Precio)}
        </h1>
      ))}
      <div>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          message="¡Producto agregado con exito!"
          key={vertical + horizontal}
          autoHideDuration={500}
        />
      </div>
    </div>
  );
};
