import React from "react";
import { Routes, Route } from "react-router-dom";
import { Home } from "../components/Home";
import { TarjetaPantalla } from "../components/TarjetaPantalla";
import { Carrito } from "../components/Carrito";
import { NavBar } from "../ui/NavBar";
import { NavProductos } from "../ui/NavProducts";
import Footer from "../components/Footer";
import { AcercaDe } from "../components/AcercaDe";
import { Contacto } from "../components/Contacto";
import { Header } from "../components/Header";
export const DashboardRoutes = () => {
  return (
    <>
      <Header></Header>
      <NavBar />

      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="productos" element={<NavProductos />} />
          <Route path="/producto/:productoId" element={<TarjetaPantalla />} />
          <Route path="/acercade" element={<AcercaDe />} />
          <Route path="/carrito" element={<Carrito />} />
          <Route path="/contacto" element={<Contacto />} />
          {/* <Route exact path="/promos" component={Promociones} /> */}

          {/* <Navigate to="/home" /> */}
          {/* <Route path="/" element={<Home/>} /> */}
        </Routes>
      </div>
      <Footer></Footer>
    </>
  );
};
